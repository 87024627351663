import React, { useEffect, useState, useCallback } from 'react';
import CalendarList from './CalendarList';
import CreateCalendar from './CreateCalendar';
import { getCookieFromBrowser } from '../../utils/cookie';
import axios from 'axios';
import './index.scss';

function GoggleCalendar() {
  const [googleList, setgoogleList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [newCalendarData, setNewCalendarData] = useState({
    summary: '',
    description: ''
  });

  useEffect(() => {
    const fetchGoogle = async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/calendar',
        headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` },
        params: { type: 'list' }
      });

      if (data) {
        setgoogleList(data.items);
      }
    };
    fetchGoogle();

  }, []);

  const onChangeData = useCallback((event) => {

    setNewCalendarData(prev => ({ ...prev, [event.target.name]: event.target.value }));
  }, [newCalendarData]);

  const handleModal = () => setIsOpenModal(!isOpenModal);

  const deleteCalendar = async (id) => {
    const data = await axios({
      method: 'Delete',
      url: '/calendar/google',
      headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` },
      data: {
        id: id,
      }
    });
    if (data) {
      console.log('Calendar deleted!');
      setgoogleList(googleList.filter(item => item.id !== id));
    }
  };

  const createNewCalendar = useCallback(async () => {
    const data = await axios({
      method: 'Post',
      url: '/calendar/google',
      headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` },
      data: {
        summary: newCalendarData.summary,
        description: newCalendarData.description
      }
    });
    if (data) {
      setIsOpenModal(!isOpenModal);
      console.log(data.data);
      setgoogleList(prev => [...prev, { ...newCalendarData, id: Math.floor(Math.random() * (100 - 0) + 1), disabled: true }]);
      setNewCalendarData({
        summary: '',
        description: ''
      });
    };

  }, [newCalendarData]);
  return (
    <div className='google-calendar-container'>
      <CreateCalendar
        newCalendarData={newCalendarData}
        onChangeData={onChangeData}
        createNewCalendar={createNewCalendar}
        isOpenModal={isOpenModal}
        handleModal={handleModal} />
      <CalendarList googleList={googleList} deleteCalendar={deleteCalendar} />
    </div>
  );
}

export default GoggleCalendar;
