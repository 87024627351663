import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { errorToast, successToast } from '../../utils/toast';
import { GetMondayProjets, GetMondayGroupsByBoardId } from '../../helpers';

// styles
import { Input, InputLabel, TextField, Switch } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// helpers
import { getTime, getStartTime, getDurationByHour, getDuration } from './helpers';
import useDeviceDetect from '../../hooks/useDeviceDetect';

const SubmitTime = ({ activities, user }) => {
  const today = new Date();
  const [description, setDescription] = useState('');
  const [project, setProject] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [projectsList, setProjectsList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [clear, setClear] = useState(false);
  const [activity, setActivity] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [hour, setHour] = useState('01:00');
  const [date, setDate] = useState(today.toISOString().split('T')[0]);
  const [checked, setChecked] = useState(true);

  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    GetMondayProjets().then((projets) => setProjectsList(projets));
  }, [clear]);

  useEffect(() => {
    if (user?.mondayBoardId && user?.mondayUserId) {
      GetMondayGroupsByBoardId(user.mondayBoardId).then((group) => setGroupList(group));
    }
  }, [user]);

  const handleSubmit = (type) => {
    if (description.length === 0) errorToast('Please fill all fields.');
    else if (project.length === 0) errorToast('Please fill all fields.');
    else if (activity.length === 0) errorToast('Please fill all fields.');
    else
      axios({
        method: 'POST',
        data: {
          description,
          project: project.name,
          projectId: project.id,
          activity: activity.name,
          activityId: activity.id,
          start: checked ? getTime(start) : getStartTime(today, hour),
          end: checked ? getTime(end) : today.toISOString(),
          duration: checked ? getDuration(start, end) : getDurationByHour(hour),
          user: user.id,
          type: 'website',
          groupId: groupId && groupId,
        },
        params: { type },
        url: `/events`
      })
        .then((res) => {
          successToast('Time is successfully tracked');
          handleClear();
        })
        .catch((e) => errorToast('Something went wrong.'));
  };

  const handleClear = () => {
    setDescription('');
    setProject('');
    setActivity('');
    setEnd('');
    setStart('');
    setGroupId('');
    setClear(!clear);
  };

  const mobileButtonStyle = isMobile
    ? { display: 'flex', marginTop: '10px' }
    : { display: 'flex', flexDirection: 'column' };

  const isMobileWidth = { width: isMobile ? '100%' : 220 };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ margin: 10 }}></div>
      <div style={{ margin: '10px' }}>
        <InputLabel id="label">Description</InputLabel>
        <Input
          style={isMobileWidth}
          onChange={(event) => setDescription(event.target.value)}
          value={description}
        />
      </div>
      <div style={{ margin: '10px' }}>
        <InputLabel id="project">Project</InputLabel>
        <Autocomplete
          key={`auto-complete-projects-${clear}`}
          id="projects-auto-complete"
          options={projectsList}
          getOptionLabel={(option) => (option.name ? option.name : '')}
          onSelect={(event) => {
            setProject(projectsList.filter(group => group.name === event.target.value)[0]);
          }}
          style={isMobileWidth}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
      <div style={{ margin: '10px' }}>
        <InputLabel id="activity">Activity</InputLabel>
        <Autocomplete
          key={`auto-complete-activities-${clear}`}
          id="activities-auto-complete"
          options={activities}
          getOptionLabel={(option) => (option?.name ? option.name : '')}
          onSelect={(event) => {
            setActivity(activities.filter(group => group.name === event.target.value)[0]);
          }}
          style={isMobileWidth}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
      {user?.mondayBoardId && user?.mondayUserId && <div style={{ margin: '10px' }}>
        <InputLabel id="activity">Monday Groups</InputLabel>
        <Autocomplete
          key={`auto-complete-groups-${clear}`}
          id="groups-auto-complete"
          options={groupList}
          getOptionLabel={(option) => (option?.title ? option.title : '')}
          onSelect={(event) => {
            setGroupId(groupList.filter(group => group.title === event.target.value).map(el => el.id)[0]);
          }}
          style={isMobileWidth}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: 10,
          alignItems: 'center',
          justifyContent: isMobile ? 'center' : 'initial'
        }}
      >
        <p>Hours</p>
        <Switch
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          color="default"
        />
        <p>Range</p>
      </div>

      {checked ? (
        <div>
          <div style={{ margin: 10 }}>
            <TextField
              id="datetime-local"
              label="Start"
              type="datetime-local"
              style={isMobileWidth}
              value={start}
              onChange={(event) => {
                setStart(event.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>

          <div style={{ margin: 10 }}>
            <TextField
              id="datetime-local"
              label="End"
              type="datetime-local"
              style={isMobileWidth}
              value={end}
              onChange={(event) => setEnd(event.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div style={{ margin: 10 }}>
            <TextField
              id="datetime-local"
              label="Date"
              type="date"
              defaultValue={date}
              style={isMobileWidth}
              onChange={(event) => setDate(event.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div style={{ margin: 10 }}>
            <TextField
              label="Hours"
              type="time"
              style={isMobileWidth}
              value={hour}
              onChange={(event) => setHour(event.target.value)}
            />
          </div>
        </div>
      )}
      <div style={mobileButtonStyle}>
        <button
          className="download-btn"
          style={{ margin: 10, padding: 12, width: 200, cursor: 'pointer' }}
          onClick={() => handleSubmit('submit')}
        >
          Submit Time
        </button>
        <button
          className="download-btn"
          style={{ margin: 10, padding: 12, width: 200, cursor: 'pointer' }}
          onClick={() => handleSubmit('todo')}
        >
          Submit ToDo
        </button>
      </div>
    </div>
  );
};

export default SubmitTime;
