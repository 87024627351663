import React, { useState } from 'react';
import Loader from '../../components/loader';
import Show from '../../components/users/show';
import { getCookieFromBrowser } from '../../utils/cookie';
import { GetUserAndCalendar } from '../../helpers';

const token = getCookieFromBrowser('Authorization');

const User = (props) => {
  const { id } = props.match.params;

  const { loading, user, calendar } = GetUserAndCalendar(id, token);

  return <div>{loading ? <Loader /> : <Show user={user} calendar={calendar} />}</div>;
};

export default User;
