import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, MenuItem, Select, InputLabel } from '@material-ui/core';
import axios from 'axios';
import { getCookieFromBrowser } from '../../utils/cookie';
import Loader from '../../components/loader';

const styles = {
  field: {
    width: '250px'
  }
};

const { field } = styles;

const UpdateUser = (props) => {
  const { state } = props.location;
  const [calendars, setCalendars] = useState([]);
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(state);
  const [mondayCredentials, setMondayCredentials] = useState([]);

  useEffect(() => {
    const fetchMonday = async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/monday/calendar',

        headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` },
        params: { type: 'users' }
      });

      if (data) {
        setMondayCredentials(data.users);
      }
    };

    const fetchGoogle = async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/calendar',
        headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` },
        params: { type: 'list' }
      });

      if (data) {
        setCalendars(data.items);
      }
    };

    const fetchBoards = async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/monday/calendar',
        headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` },
        params: { type: 'boards' }
      });
      if (data) {
        setBoards(data.boards);
      }
    };

    setLoading(true);
    fetchMonday();
    fetchGoogle();
    fetchBoards();
    setLoading(false);
  }, []);

  const changeHandler = (event) => {
    if (
      event.target.id === 'mondayUserId' ||
      event.target.id === 'pause' ||
      event.target.id === 'kost' ||
      event.target.id === 'salgspris'
    ) {
      setForm({ ...form, [event.target.id]: (event.target.value *= 1) });
    } else {
      setForm({
        ...form,
        [event.target.id]: event.target.value
      });
    }
  };

  const timeSelectHandler = (event) => {
    setForm({ ...form, timeGoogleCalendarId: event.target.value });
  };

  const toDoSelectHandler = (event) => {
    setForm({ ...form, toDoGoogleCalendarId: event.target.value });
  };

  const mondayIdSelectHandler = (event) => {
    const mondayUser = mondayCredentials.find((item) => item.id === event.target.value);

    let userBoardId = 0;
    boards.map((board) =>
      board.subscribers.map((subs) =>
        subs.id === event.target.value * 1 && userBoardId === 0
          ? (userBoardId = board.id * 1)
          : null
      )
    );

    setForm({
      ...form,
      mondayUserId: event.target.value,
      picture: mondayUser.photo_original,
      mondayBoardId: userBoardId
    });
  };

  const mondayBoardIdSelectHandler = (event) => {
    setForm({
      ...form,
      mondayBoardId: event.target.value * 1
    });
  };

  const updateHandler = async () => {
    setLoading(true);
    await axios(`/users/${state.id}`, {
      method: 'PUT',
      data: form,
      headers: {
        Authorization: `Bearer ${getCookieFromBrowser('Authorization')}`
      }
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          // window.location.replace('http://localhost:3000/');
          window.location.replace(`https://time.i2a.no/users/${state.id}`);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Paper>
          <div style={{ padding: '24px' }}>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="name"
                  label="Name"
                  type="text"
                  style={field}
                  value={form.name}
                  autoFocus
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <InputLabel id="time">Time Google Calendar</InputLabel>
                <Select
                  id="timeGoogleCalendarId"
                  labelId="time"
                  defaultValue={form.timeGoogleCalendarId}
                  style={field}
                  onChange={timeSelectHandler}
                >
                  {calendars.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.summary}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <InputLabel id="todo">ToDo Google Calendar</InputLabel>
                <Select
                  id="toDoGoogleCalendarId"
                  labelId="todo"
                  defaultValue={form.toDoGoogleCalendarId}
                  style={field}
                  onChange={toDoSelectHandler}
                >
                  {calendars.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.summary}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <InputLabel id="mondayUserIdTitle">Reference to Monday User</InputLabel>
                <Select
                  id="mondayUserId"
                  labelId="mondayUserIdTitle"
                  defaultValue={form.mondayUserId}
                  style={field}
                  onChange={mondayIdSelectHandler}
                >
                  {mondayCredentials.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {/*<TextField*/}
                {/*  id="mondayUserId"*/}
                {/*  label="Monday User ID"*/}
                {/*  type="text"*/}
                {/*  style={field}*/}
                {/*  value={form.mondayUserId}*/}
                {/*  onChange={changeHandler}*/}
                {/*/>*/}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <InputLabel id="mondayBoardIdTitle">Reference to Monday Board</InputLabel>
                <Select
                  id="mondayBoardId"
                  labelId="mondayBoardIdTitle"
                  value={form.mondayBoardId}
                  style={field}
                  onChange={mondayBoardIdSelectHandler}
                >
                  {boards.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>

                {/*<TextField*/}
                {/*  id="mondayBoardId"*/}
                {/*  label="Monday Board ID"*/}
                {/*  type="text"*/}
                {/*  style={field}*/}
                {/*  value={form.mondayBoardId}*/}
                {/*  onChange={changeHandler}*/}
                {/*/>*/}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="dag"
                  label="Dag"
                  type="text"
                  style={field}
                  value={form.dag}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="pause"
                  label="Pause"
                  type="text"
                  style={field}
                  value={form.pause}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="kost"
                  label="Kost"
                  type="text"
                  style={field}
                  value={form.kost}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="salgspris"
                  label="Salgspris"
                  type="text"
                  style={field}
                  value={form.salgspris}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="lonnsart"
                  label="Lonnsart"
                  type="text"
                  style={field}
                  value={form.lonnsart}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="avdeling"
                  label="Avdeling"
                  type="text"
                  style={field}
                  value={form.avdeling}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="ansatt"
                  label="Ansatt"
                  type="text"
                  style={field}
                  value={form.ansatt}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="arbeidstype"
                  label="Arbeidstype"
                  type="text"
                  style={field}
                  value={form.arbeidstype}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  style={field}
                  value={form.email}
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '10px' }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={updateHandler}
              >
                Save
              </Button>
            </Grid>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default UpdateUser;
