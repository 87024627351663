import React from 'react';
import { GetGCalendars } from '../../helpers';
import { getCookieFromBrowser } from '../../utils/cookie';
import Loader from '../../components/loader';

const token = getCookieFromBrowser('Authorization');

const Calendars = () => {
  const { loading, calendars } = GetGCalendars(token);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        calendars.items.map((calendar) => (
          <h1 style={{ color: calendar.backgroundColor }}>{calendar.summary}</h1>
        ))
      )}
    </div>
  );
};

export default Calendars;
