import axios from 'axios';
import { useEffect, useState } from 'react';
import { getCookieFromBrowser } from './utils/cookie';

export const getUser = (token) => {
  return axios({
    method: 'GET',
    url: '/users/me',
    headers: { Authorization: `Bearer ${token}` }
  });
};

function GetGCalendars(token) {
  const [loading, setLoading] = useState(true);
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/calendar',
      headers: { Authorization: `Bearer ${token}` },
      params: { type: 'list' }
    })
      .then((res) => {
        setCalendars(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return { loading, calendars };
}

function GetUserByToken(token) {
  const [userLoading, setUserLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/users/me',
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((res) => {
        setUser(res.data);
        setUserLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);
  return { userLoading, user };
}

function GetUserById(id) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/users/${id}`
    })
      .then((res) => {
        setUser(res.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [id]);
  return { loading, user };
}

function GetUsers(token) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/users',
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [token]);

  return { loading, users };
}

function GetUserAndCalendar(id, token) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [calendar, setCalendar] = useState({});

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/users/${id}`
    })
      .then((res) => {
        setUser(res.data);
        axios({
          method: 'GET',
          url: '/calendar',
          headers: { Authorization: `Bearer ${token}` },
          params: { type: 'get', calendarId: res.data.googleCalendarId }
        })
          .then((res) => {
            setCalendar(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [token, id]);

  return { loading, user, calendar };
}

function GetMonday() {
  const authToken = getCookieFromBrowser('Authorization');
  const [GMLoading, setGMLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    setGMLoading(true);
    axios({
      method: 'GET',
      url: `/monday/calendar`,
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        type: 'events',
        boardId: '977994216',
        isSortedProjects: true
      }
    })
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch((e) => console.log(e));

    axios({
      method: 'GET',
      url: `/monday/calendar`,
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        type: 'events',
        boardId: '1009126277'
      }
    })
      .then((res) => {
        setActivities(res.data.boards[0].items);
      })
      .catch((e) => console.log(e));
    setGMLoading(false);
  }, []);

  return { GMLoading, projects, activities };
}

function GetTime(user, isApprove) {
  const [timeLoading, setTimeLoading] = useState(false);
  const [time, setTime] = useState([]);

  useEffect(() => {
    setTimeLoading(true);
    axios({
      method: 'GET',
      url: `/events`,
      params: {
        user: user ? user : null,
        isApprove: isApprove ? isApprove : null
      }
    })
      .then((res) => {
        setTime(res.data.rows);
      })
      .catch((e) => console.log(e));
    setTimeLoading(false);
  }, [user]);

  return { timeLoading, time };
}

const GetMondayProjets = () => {
  const authToken = getCookieFromBrowser('Authorization');
  return axios({
    method: 'GET',
    url: `/monday/calendar`,
    headers: { Authorization: `Bearer ${authToken}` },
    params: {
      type: 'events',
      boardId: '977994216',
      isSortedProjects: true
    }
  })
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
};

const GetMondayGroupsByBoardId = (boardId) => {
  const authToken = getCookieFromBrowser('Authorization');
  return axios({
    method: 'GET',
    url: `/monday/calendar/groups`,
    headers: { Authorization: `Bearer ${authToken}` },
    params: {
      boardId: boardId,
    }
  })
    .then((res) => res.data.groups)
    .catch((e) => console.log(e));
}

export {
  GetGCalendars,
  GetUserByToken,
  GetUsers,
  GetUserById,
  GetUserAndCalendar,
  GetMonday,
  GetTime,
  GetMondayProjets,
  GetMondayGroupsByBoardId
};
