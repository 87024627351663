import axios from 'axios';
import { getCookieFromBrowser } from '../utils/cookie';

export const changeTheme = (theme) => {
  const authToken = getCookieFromBrowser('Authorization');

  return axios.put(
    `/users/theme`,
    { theme: theme },
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
};
