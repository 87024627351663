import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { getCookieFromBrowser } from './utils/cookie';
import Header from './components/header/index';
import Home from './containers/home';
import Calendars from './containers/calendars';
import Users from './containers/users';
import Create from './containers/users/create';
import UpdateUser from './containers/users/update';
import Authorization from './containers/auth';
import Time from './containers/time/index';
import Submit from './containers/time/submit';
import ApproveTime from './containers/time/approve';
import Invoice from './containers/time/invoice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFetching } from './hooks/useFetching';
import UserService from './API/UserService';
import Activities from './containers/activities';
import { CustomThemeProvider } from './themes/CustomThemeProvider';
import User from './containers/users/show';
import GoogleCalendar from './components/google-calendar';

function App() {
  const [user, setUser] = useState({});
  const authToken = getCookieFromBrowser('Authorization');

  const [fetchUser, isUserLoading, userError] = useFetching(async () => {
    const user = await UserService.getByToken(authToken);
    setUser(user);
  });

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <CustomThemeProvider>
      <Router>
        <Header />
        <ToastContainer />
        {authToken ? (
          <Switch>
            <Route exact path="/" component={Submit} />
            <Route exact path="/time" component={() => <Time user={user} />} />
            <Route exact path="/time/approve" component={ApproveTime} />
            <Route exact path="/time/invoice" component={() => <Invoice user={user} />} />
            <Route path="/download" component={Home} />
            <Route path="/calendars" component={Calendars} />
            <Route path="/users" component={Users} />
            <Route exact path="/users/:id" component={User} />
            <Route strict path="/create/user" component={Create} />
            <Route exact path="/user/update/:id" component={UpdateUser} />
            <Route exact path="/time/approve" component={ApproveTime} />
            <Route exact path="/google/calendar" component={GoogleCalendar} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/auth" exact component={Authorization} />
            <Redirect to="/auth" />
          </Switch>
        )}
      </Router>
    </CustomThemeProvider>
  );
}

export default App;
