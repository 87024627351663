import React from 'react';
import { toast } from 'react-toastify';

// Toast pruning
const successToast = (title, description) => {
  toast.success(
    <div>
      <div style={{ fontWeight: 600 }}> {title} </div>
      <div>{description}</div>
    </div>,
    {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    }
  );
};

const errorToast = (title, description) => {
  toast.error(
    <div>
      <div style={{ fontWeight: 600 }}> {title} </div>
      <div>{description}</div>
    </div>,
    {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    }
  );
};

export { successToast, errorToast };

export default {
  successToast,
  errorToast
};
