import moment from 'moment';

export function getTime(date) {
  const dateSplit = date.split('T');
  const year = dateSplit[0].split('-')[0] * 1;
  const month = dateSplit[0].split('-')[1] * 1 - 1;
  const day = dateSplit[0].split('-')[2] * 1;
  const hour = dateSplit[1].split(':')[0] * 1;
  const minute = dateSplit[1].split(':')[1] * 1;

  return new Date(year, month, day, hour, minute).toISOString();
}

export function getStartTime(today, hour) {
  const hourSplit = hour.split(':');
  const hours = hourSplit[0] * 1;
  const minutes = hourSplit[1] * 1;

  const date = new Date(today);

  const res = date.setHours(date.getHours() - hours, date.getMinutes() - minutes);
  return new Date(res).toISOString();
}

export function getDuration(start, end) {
  const date1 = new Date(start).getTime();
  const date2 = new Date(end).getTime();
  const diffMs = Math.abs(date1 - date2);
  const diffDays = Math.floor(diffMs / 86400000);
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  return diffDays * 24 * 60 + diffHrs * 60 + diffMins;
}

export function getDurationByHour(hour) {
  return hour.split(':')[0] * 60 + hour.split(':')[1] * 1;
}

export const getCurrentDate = () => moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss');
