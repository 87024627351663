import React, { useEffect, useState } from 'react';

// services
import { getUserActivities } from '../../API/activitiesService';
import CustomCalendar from './calendar';
import StakedBarChart from './chart';

//styles

function Activities() {
  const [activitiesList, setActivitiesList] = useState([]);
  const [activitiesAnalitics, setAactivitiesAnalitics] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    getUserActivities().then((activities) => {
      setAactivitiesAnalitics(activities?.data?.activitiesAnalitics);
      setActivitiesList(activities?.data?.activitiesList);
    });

    return () => {
      setAactivitiesAnalitics([]);
      setActivitiesList([]);
    };
  }, []);
  // console.log('activitiesAnalitics', activitiesAnalitics);
  // console.log('activitiesList', activitiesList);
  return (
    // <div>
    //   <CustomCalendar setDate={setDate} date={date} />
    //   {activitiesList && activitiesAnalitics && (
    //     <StakedBarChart activitiesList={activitiesList} activitiesAnalitics={activitiesAnalitics} />
    //   )}
    // </div>
    <></>
  );
}

export default Activities;
