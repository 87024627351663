import React from 'react';
import { number } from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

const Loader = (props) => {
  const { py } = props;

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} py={py}>
      <CircularProgress />
    </Box>
  );
};

Loader.propTypes = {
  py: number
};

Loader.defaultProps = {
  py: 8
};

export default Loader;
