import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Loader from '../../components/loader';
import { getGridData, timeConvert } from './helper';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import moment from 'moment';
import axios from 'axios';
import { successToast } from '../../utils/toast';

import { Typography, Grid, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { useFetching } from '../../hooks/useFetching';
import EventService from '../../API/EventService';
import MondayService from '../../API/MondayService';
import { useSelector } from 'react-redux';
import { THEME_TYPES, WHITE } from '../../constants/constants';
import useDeviceDetect from '../../hooks/useDeviceDetect';

import '@inovua/reactdatagrid-enterprise/base.css';
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/theme/default-light.css';

window.moment = moment;

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  grid: {
    minHeight: 500,
    overflowY: 'auto',
    overflowX: 'auto'
  }
};
const { container, grid } = style;

const Time = ({ user }) => {
  const { theme, usedUser } = useSelector((state) => state);
  const { isMobile } = useDeviceDetect();
  const [selected, setSelected] = useState({});
  const [body, setBody] = useState({ project: '', activity: '' });
  const [gridData, setGridData] = useState({
    rows: [],
    projects: [],
    activities: [],
    users: [],
    types: []
  });
  const [projects, setProjects] = useState([]);
  const [activities, setActivities] = useState([]);
  const [ifUpdate, setIfUpdate] = useState(1);
  const [fetchEvents, isLoading, error] = useFetching(async () => {
    const events = await EventService.getAll(user.role === 'admin' ? null : user.id);
    setGridData(getGridData(events, 'overview'));
  });

  const [fetchProjects, isProjectsLoading, projectsError] = useFetching(async () => {
    const projects = await MondayService.getProjects();
    setProjects(projects);
  });
  const [fetchActivities, isActivitiesLoading, activitiesError] = useFetching(async () => {
    const activities = await MondayService.getActivities();
    setActivities(activities);
  });

  useEffect(() => {
    fetchEvents();
    fetchProjects();
    fetchActivities();
  }, [ifUpdate]);

  const filterValue = [
    {
      name: 'project',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'activity',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'start',
      operator: 'afterOrOn',
      type: 'date'
    },
    {
      name: 'end',
      operator: 'beforeOrOn',
      type: 'date'
    },
    {
      name: 'type',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'isApproved',
      operator: 'eq',
      type: 'boolean'
    }
  ];

  const columns = [
    {
      name: 'description',
      defaultFlex: 1,
      header: 'Title',
      render: ({ value }) => value
    },
    {
      name: 'userName',
      defaultFlex: 1,
      header: 'User',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.users },
      render: ({ value }) => value
    },
    {
      name: 'project',
      defaultFlex: 1,
      header: 'Project',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.projects },
      render: ({ value }) => value
    },
    {
      name: 'activity',
      defaultFlex: 1,
      header: 'Activity',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.activities },
      render: ({ value }) => value
    },
    {
      name: 'start',
      header: 'Start Date',
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: 'Created date is after...'
      },
      render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
    },
    {
      name: 'end',
      header: 'End Date',
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: 'Created date is before...'
      },
      render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
    },
    {
      name: 'duration',
      defaultFlex: 1,
      header: 'Total Spent Time',
      render: ({ value }) => value ? timeConvert(value) : 'no spend time'
    },
    {
      name: 'type',
      defaultFlex: 1,
      header: 'Submited from',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.types },
      render: ({ value }) => value
    },
    {
      name: 'isApproved',
      defaultFlex: 1,
      header: 'Approved',
      filterEditor: BoolFilter,
      render: ({ value }) => value.toString()
    }
  ];

  if (user.role === 'admin') {
    filterValue.push({
      name: 'userName',
      operator: 'eq',
      type: 'string',
      value: usedUser.name || user.name
    });
  }

  const handleSelection = useCallback(
    ({ selected }) => {
      const selectedObj = gridData.rows.find((record) => record.id === selected);
      setSelected(selectedObj);
      setBody({ project: selectedObj.project, activity: selectedObj.activity });
    },
    [gridData.rows]
  );

  const handleSave = () => {
    axios({
      method: 'PUT',
      data: body,
      url: `/events/${selected.id}`
    })
      .then(({ data }) => {
        successToast('success', 'success');
      })
      .catch((e) => console.error(e));
    setIfUpdate(ifUpdate + 1);
  };

  const memoTheme = useMemo(() => {
    return theme === WHITE ? THEME_TYPES[0] : THEME_TYPES[1];
  }, [theme]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={container}>
          <ReactDataGrid
            idProperty="id"
            resizable={true}
            style={grid}
            defaultFilterValue={filterValue}
            columns={columns}
            columnMaxWidth={isMobile && 200}
            columnMinWidth={isMobile && 200}
            theme={memoTheme}
            dataSource={gridData.rows}
            enableSelection={true}
            onSelectionChange={handleSelection}
          />

          <Grid container spacing={3} flex justifyContent={'center'} flexDirection={'column'}>
            <Grid item>
              <Typography variant="h6" sx={{ m: 1 }}>
                {selected.description}
              </Typography>
            </Grid>
            <Grid container item spacing={3} flex alignItems={'center'}>
              <Grid item>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 220 }}>
                  <InputLabel id="project">Project</InputLabel>
                  {isProjectsLoading ? (
                    <Loader />
                  ) : (
                    <Select
                      labelId="project"
                      onChange={(event) => setBody({ ...body, project: event.target.value })}
                      defaultValue=""
                      value={body.project ? body.project : ''}
                      label="Project"
                      disabled={!selected.id}
                    >
                      {projects.map((x) => (
                        <MenuItem value={x.name} key={x.name}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 220 }}>
                  <InputLabel id="activity">Activity</InputLabel>
                  {isActivitiesLoading ? (
                    <Loader />
                  ) : (
                    <Select
                      labelId="activity"
                      onChange={(event) => setBody({ ...body, activity: event.target.value })}
                      defaultValue=""
                      value={body.activity ? body.activity : ''}
                      label="Activity"
                      disabled={!selected.id}
                    >
                      {activities.map((x) => (
                        <MenuItem value={x.name} key={x.name}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSave()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Time;
