import axios from 'axios';

export default class UserService {
  static async getByToken(token) {
    const response = await axios.get('/users/me', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  }
}
