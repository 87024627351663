import React from 'react';
import './index.scss';

function CalendarList({ googleList, deleteCalendar }) {
  return (
    <div className='google-calendar-container-list'>
      {googleList?.map((item) => {
        return (
          <div key={item.id} className='google-calendar-container-list-item'>
            <p className='google-calendar-container-list-item-title'>{item.summary}</p>
            {!item?.disabled &&
              <div className='google-calendar-container-list-item-delete-btn' onClick={() => deleteCalendar(item.id)}>delete</div>}
          </div>);
      })}
    </div>
  );
}

export default CalendarList;
