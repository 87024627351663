import AuthForm from "../../components/auth-form";

const Authorization = () => {
  return (
    <div className="auth-container">
      <AuthForm />
    </div>
  );
};

export default Authorization;
