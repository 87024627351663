import { WHITE } from '../constants/constants';
import { SET_THEME, SET_USER_FILTER, CLEAR_USER_FILTER } from './types';

const initialState = {
  theme: WHITE,
  usedUser: {
    name: null,
    id: null
  }
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload
      };
    case SET_USER_FILTER:
      return {
        ...state,
        usedUser: action.payload
      };
    case CLEAR_USER_FILTER:
      return {
        ...state,
        usedUser: {
          name: null,
          id: null
        }
      };
    default:
      return state;
  }
};

export default rootReducer;
