import React, { useState } from 'react';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import axios from 'axios';
import { getCookieFromBrowser } from '../../utils/cookie';

const styles = {
  field: {
    width: '250px'
  }
};

const { field } = styles;

const Create = () => {
  const [form, setForm] = useState();

  const changeHandler = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value
    });
  };

  const createHandler = async () => {
    await axios(`/users`, {
      method: 'POST',
      data: form,
      headers: {
        Authorization: `Bearer ${getCookieFromBrowser('Authorization')}`
      }
    })
      .then((res) => {
        if (res.data) {
          // window.location.replace(`http://localhost:3000/users/${res.data.user.id}`);
          window.location.replace(`https://time.i2a.no/users/${res.data.user.id}`);
        } else console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    console.log(form);
  };

  return (
    <div>
      <Paper>
        <div style={{ padding: '24px' }}>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                id="name"
                label="Name"
                type="text"
                inputProps={{
                  autoComplete: 'off'
                }}
                style={field}
                autoFocus
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                id="password"
                label="Password"
                type="password"
                inputProps={{
                  autoComplete: 'off'
                }}
                style={field}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '10px' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => createHandler()}
            >
              Create
            </Button>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default Create;
