import React, { useState, useEffect } from 'react';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import SideBar from '../../components/sidebar';
import SideBarBackDrop from '../../components/sidebar-backdrop';
import HeaderMobile from './HeaderMobile';
import HeaderDesctop from './HeaderDesctop';
import { getCookieFromBrowser } from '../../utils/cookie';
import { GetUserByToken } from '../../helpers';
import { changeTheme } from '../../API/changeTheme';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../../helpers';

import { setNewTheme } from '../../redux/actions';
import { DARK } from '../../constants/constants';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { clearUsedFilter } from '../../redux/actions';
const themeStyle = { display: 'flex', alignItems: 'center' };

function Header() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { theme, usedUser } = useSelector((state) => state);

  const authToken = getCookieFromBrowser('Authorization');
  const { user } = GetUserByToken(authToken);
  const { isMobile } = useDeviceDetect();
  const dispatch = useDispatch();

  const handleClearFilter = () => {
    dispatch(clearUsedFilter());
  };

  const drawerToggleClickHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const backdropClickHandler = () => {
    setSideBarOpen(false);
  };

  const handleChangeTheme = () => {
    changeTheme(theme)
      .catch((err) => console.log('err', err))
      .then((res) => {
        dispatch(setNewTheme(res.data.theme));
      });
  };

  useEffect(() => {
    getUser(authToken).then((res) => {
      dispatch(setNewTheme(res.data.theme));
    });
  }, []);

  const currentThmeIcon =
    theme === DARK ? (
      <div style={themeStyle}>
        Dark
        <Brightness4Icon />
      </div>
    ) : (
      <div style={themeStyle}>
        Light
        <Brightness7Icon />
      </div>
    );

  return (
    <>
      {isMobile ? (
        <>
          <SideBar
            usedUser={usedUser}
            handleClearFilter={handleClearFilter}
            currentThmeIcon={currentThmeIcon}
            show={sideBarOpen}
            handleChangeTheme={handleChangeTheme}
            theme={theme}
            click={drawerToggleClickHandler}
          />
          {sideBarOpen && <SideBarBackDrop click={backdropClickHandler} />}
          <HeaderMobile click={drawerToggleClickHandler} showMenuButton={isMobile} />
        </>
      ) : (
        <HeaderDesctop
          usedUser={usedUser}
          currentThmeIcon={currentThmeIcon}
          handleChangeTheme={handleChangeTheme}
          role={user.role}
          authToken={authToken}
        />
      )}
    </>
  );
}

export default Header;
