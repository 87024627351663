import React from 'react';
import { getCookieFromBrowser, removeCookie } from '../../utils/cookie';
import menu from './menu.svg';

export const delCookie = async () => {
  removeCookie('Authorization');
};

export const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#4eb5f1',
    justifyContent: 'space-between'
  },
  link: {
    marginLeft: '24px',
    textDecoration: 'none',
    fontWeight: 600
  },
  item: {
    padding: '24px'
  },
  menuButton: {
    display: 'flex',
    padding: 0,
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  },
  logOut: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    color: 'white',
    fontWeight: 800,
    textTransform: 'uppercase'
  }
};

const { header, link, item, menuButton } = styles;

const HeaderMobile = ({ click, showMenuButton }) => {
  const authToken = getCookieFromBrowser('Authorization');
  return (
    <header>
      <div style={header}>
        <div style={item}>
          {showMenuButton && authToken && (
            <button style={menuButton} onClick={click}>
              <img src={menu} alt="Menu-Button" />
            </button>
          )}
        </div>
        <div style={item}>
          {authToken ? (
            <a href="/" style={link} onClick={delCookie}>
              Logout
            </a>
          ) : (
            <a href="/auth">Login</a>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderMobile;
