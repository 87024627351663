import React, { useState } from 'react';
import SubmitTime from '../../components/submit-time';
import { GetMonday, GetUserByToken } from '../../helpers';
import { getCookieFromBrowser } from '../../utils/cookie';
import Loader from '../../components/loader';

const token = getCookieFromBrowser('Authorization');

const Submit = () => {
  const { userLoading, user } = GetUserByToken(token);
  const { GMLoading, activities } = GetMonday();

  return userLoading || GMLoading ? (
    <div>
      <Loader />
    </div>
  ) : (
    <div>
      <SubmitTime activities={activities} user={user} />
    </div>
  );
};

export default Submit;
