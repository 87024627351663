import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './index.scss';

function CreateCalendat({ newCalendarData, onChangeData, createNewCalendar, isOpenModal, handleModal }) {

  const [isDisabledCreateButton, setIsDisabledCreateButton] = useState(false);

  useEffect(() => {
    if (newCalendarData.summary.length > 0 && newCalendarData.description.length > 0) {
      setIsDisabledCreateButton(true);
    }
    return () => setIsDisabledCreateButton(false);
  }, [newCalendarData.summary, newCalendarData.description]);

  return (
    <>
      <div className='google-calendar-container-create'>
        <button onClick={() => handleModal()} className='google-calendar-container-create-button'>Create Calendar</button>
        <h3>Google Calendar List</h3>
        <div></div>
      </div>
      <div className={classNames('google-calendar-container-modal', {
        'google-calendar-container-modal-visible': isOpenModal
      })}>
        <h2>Create calendar</h2>

        <div className='google-calendar-container-modal-form'>
          <button onClick={() => handleModal()}
            className='google-calendar-container-modal-form-back'>Back</button>
          <h5>Summary</h5>
          <input
            className='google-calendar-container-modal-form-input'
            type='text'
            name='summary'
            value={newCalendarData.summary}
            onChange={(e) => onChangeData(e)} />
          <h5>Description</h5>
          <input
            className='google-calendar-container-modal-form-input'
            type='text'
            name='description'
            value={newCalendarData.description}
            onChange={(e) => onChangeData(e)} />
          <button onClick={() => createNewCalendar()} className={classNames('google-calendar-container-modal-form-btn', {
            'google-calendar-container-modal-form-btn-visible': isDisabledCreateButton
          })}>Create</button>

        </div>
      </div>
    </>

  );
}

export default CreateCalendat;
