import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useFetching } from '../../hooks/useFetching';
import EventService from '../../API/EventService';
import { getGridData, timeConvert } from './helper';

window.moment = moment;

const style = {
  grid: {
    minHeight: 500
  }
};
const { grid } = style;

const Invoice = ({ user }) => {
  const [filter, setFilter] = useState({
    start: moment(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
    end: moment(new Date()).format('YYYY-MM-DD')
  });
  const [gridData, setGridData] = useState({
    rows: [],
    projects: [],
    activities: [],
    users: [],
    types: []
  });

  const [fetchEvents, isLoading, error] = useFetching(async () => {
    const events = await EventService.getAll(
      null,
      filter.start.length ? new Date(filter.start) : null,
      filter.end.length ? new Date(filter.end) : null
    );
    setGridData(getGridData(events, 'invoice'));
  });

  useEffect(() => {
    fetchEvents();
  }, [filter]);

  const handleChangeFilter = (event) => {
    const start = event.find((obj) => obj.name === 'start');
    const end = event.find((obj) => obj.name === 'end');

    if (start.value !== filter.start) {
      setFilter({ ...filter, start: start.value });
    }
    if (end.value !== filter.end) {
      setFilter({ ...filter, end: end.value });
    }
  };

  const filterValue = [
    {
      name: 'userName',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'project',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'activity',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'start',
      operator: 'afterOrOn',
      type: 'date',
      value: filter.start
    },
    {
      name: 'end',
      operator: 'beforeOrOn',
      type: 'date',
      value: filter.end
    },
    {
      name: 'type',
      operator: 'eq',
      type: 'string'
    }
  ];

  const columns = [
    {
      name: 'userName',
      defaultFlex: 1,
      header: 'User',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.users },
      render: ({ value }) => value
    },
    {
      name: 'project',
      defaultFlex: 1,
      header: 'Project',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.projects },
      render: ({ value }) => value
    },
    {
      name: 'activity',
      defaultFlex: 1,
      header: 'Activity',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.activities },
      render: ({ value }) => value
    },
    {
      name: 'start',
      header: 'Start Date',
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: 'Created date is after...'
      },
      render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
    },
    {
      name: 'end',
      header: 'End Date',
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: 'Created date is before...'
      },
      render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
    },
    {
      name: 'duration',
      defaultFlex: 1,
      header: 'Total Spent Time',
      render: ({ value }) => value ? timeConvert(value) : "no spend time"
    },
    {
      name: 'type',
      defaultFlex: 1,
      header: 'Submited from',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: gridData.types
      },
      render: ({ value }) => value
    }
  ];

  return (
    <div>
      <ReactDataGrid
        idProperty="id"
        style={grid}
        checkboxColumn
        onFilterValueChange={(event) => handleChangeFilter(event)}
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={gridData.rows}
      />
    </div>
  );
};

export default Invoice;
