import axios from 'axios';
import { getCookieFromBrowser } from '../utils/cookie';
export default class MondayService {
  static async getProjects() {
    const response = await axios.get('/monday/calendar', {
      params: { type: 'events', boardId: '977994216' },
      headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` }
    });

    return response.data.boards[0].items;
  }

  static async getActivities() {
    const response = await axios.get('/monday/calendar', {
      params: { type: 'events', boardId: '1009126277' },
      headers: { Authorization: `Bearer ${getCookieFromBrowser('Authorization')}` }
    });

    return response.data.boards[0].items;
  }
}
