import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import moment from 'moment';
import '@inovua/reactdatagrid-community/index.css';
import { errorToast, successToast } from '../../utils/toast';
import axios from 'axios';
import { getGridData, timeConvert } from './helper';
import { useFetching } from '../../hooks/useFetching';
import EventService from '../../API/EventService';
import { useSelector } from 'react-redux';
import { THEME_TYPES, WHITE } from '../../constants/constants';
import useDeviceDetect from '../../hooks/useDeviceDetect';

window.moment = moment;

const style = {
  grid: {
    minHeight: 500
  },
  button: {
    position: 'absolute',
    right: 0
  }
};
const { grid, button } = style;

const ApproveTime = () => {
  const { isMobile } = useDeviceDetect();
  const [selected, setSelected] = useState({});
  const [filter, setFilter] = useState({
    start: moment(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
    end: moment(new Date()).format('YYYY-MM-DD')
  });
  const { theme, usedUser } = useSelector((state) => state);
  const [gridData, setGridData] = useState({
    rows: [],
    projects: [],
    activities: [],
    users: [],
    types: []
  });

  const [fetchEvents, isLoading, error] = useFetching(async () => {
    const events = await EventService.getAll(
      null,
      filter.start.length ? new Date(filter.start) : null,
      filter.end.length ? new Date(filter.end).setHours(23, 59, 59) : null
    );
    setGridData(getGridData(events, 'approve'));
  });

  useEffect(() => {
    fetchEvents();
  }, [filter]);

  const handleChangeFilter = (event) => {
    const start = event.find((obj) => obj.name === 'start');
    const end = event.find((obj) => obj.name === 'end');

    if (start.value !== filter.start) {
      setFilter({ ...filter, start: start.value });
    }
    if (end.value !== filter.end) {
      setFilter({ ...filter, end: end.value });
    }
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const handleSubmit = () => {
    if (Object.keys(selected).length === 0) errorToast('Please select at least one item.');
    else {
      for (const key in selected) {
          axios({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: { isApproved: true },
            url: `/events/${selected[key].id}`
          }).then(() => {
            setGridData({ rows: gridData.rows.filter((row) => row.id !== selected[key].id)})
          })
      }
      setSelected({})
      successToast('Success', 'Approved!');
    }
  };

  const filterValue = [
    {
      name: 'userName',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'project',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'activity',
      operator: 'eq',
      type: 'string'
    },
    {
      name: 'start',
      operator: 'afterOrOn',
      type: 'date',
      value: filter.start
    },
    {
      name: 'end',
      operator: 'beforeOrOn',
      type: 'date',
      value: filter.end
    },
    {
      name: 'type',
      operator: 'eq',
      type: 'string'
    }
  ];
  if (usedUser.name) {
    filterValue.push({
      name: 'userName',
      operator: 'eq',
      type: 'string',
      value: usedUser.name
    });
  }

  const columns = [
    {
      name: 'userName',
      defaultFlex: 1,
      header: 'User',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.users },
      render: ({ value }) => value
    },
    {
      name: 'project',
      defaultFlex: 1,
      header: 'Project',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.projects },
      render: ({ value }) => value
    },
    {
      name: 'activity',
      defaultFlex: 1,
      header: 'Activity',
      filterEditor: SelectFilter,
      filterEditorProps: { placeholder: 'All', dataSource: gridData.activities },
      render: ({ value }) => value
    },
    {
      name: 'start',
      header: 'Start Date',
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: 'Created date is after...'
      },
      render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
    },
    {
      name: 'end',
      header: 'End Date',
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        placeholder: 'Created date is before...'
      },
      render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
    },
    {
      name: 'duration',
      defaultFlex: 1,
      header: 'Total Spent Time',
      render: ({ value }) => value ? timeConvert(value) : 'no spend time'
    },
    {
      name: 'type',
      defaultFlex: 1,
      header: 'Submited from',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: gridData.types
      },
      render: ({ value }) => value
    }
  ];

  const memoTheme = useMemo(() => {
    return theme === WHITE ? THEME_TYPES[0] : THEME_TYPES[1];
  }, [theme]);

  return (
    <div>
      <div>
        <ReactDataGrid
          idProperty="id"
          style={grid}
          theme={memoTheme}
          selected={selected}
          resizable={true}
          checkboxColumn
          columnMaxWidth={isMobile && 200}
          columnMinWidth={isMobile && 200}
          onSelectionChange={onSelectionChange}
          onFilterValueChange={(event) => handleChangeFilter(event)}
          defaultFilterValue={filterValue}
          columns={columns}
          dataSource={gridData.rows}
        />
      </div>
      <div style={button}>
        <button
          className="download-btn"
          style={{ margin: 10, padding: 12, width: 200 }}
          onClick={handleSubmit}
        >
          Approve
        </button>
      </div>
    </div>
  );
};

export default ApproveTime;
