import React, { useState, useEffect } from 'react';
import DateWeekPicker from '../../components/date-picker';
import '../../App.css';
import moment from 'moment';
import { getCookieFromBrowser } from '../../utils/cookie';
import Loader from '../../components/loader';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';

const downloadHoursAsCSV = async (startDate, endDate, user) => {
  const start = startDate.startOf('day').toISOString();
  const end = endDate.endOf('day').toISOString();
  const dateFormat = moment.localeData().longDateFormat('L');

  const token = getCookieFromBrowser('Authorization');

  const response = await fetch(`https://api.time.i2a.no/calendar/csv?userId=${user.id}`, {
    method: 'POST',
    body: JSON.stringify({ start, end }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  let fileName = '';
  user.ansatt.split(' ').map((s) => (fileName += `${s}_`));

  const blob = await response.blob();
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.setAttribute(
    'download',
    `${fileName}${startDate.format(dateFormat)}-${endDate.format(dateFormat)}.csv`
  );
  a.click();
};

const getEndDate = (date, dayOfWeek = 3) => {
  const today = moment(date).isoWeekday();
  // if we haven't yet passed the day of the week that I need:
  if (today <= dayOfWeek) {
    // then just give me this week's instance of that day
    return moment(date).isoWeekday(dayOfWeek);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment(date)
      .add(1, 'weeks')
      .isoWeekday(dayOfWeek);
  }
};

const getStartEndDate = (date) => {
  const end = getEndDate(date);
  const start = end.clone().subtract(6, 'days');
  return [start, end];
};

function Home() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(false);
  const { usedUser } = useSelector((state) => state);

  const authToken = getCookieFromBrowser('Authorization');

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'GET',
      url: '/users/me',
      headers: { Authorization: `Bearer ${authToken}` }
    })
      .then(({ data }) => {
        setUser(data);
        setSelectedUser(data);
        if (data.role === 'admin') {
          axios({
            method: 'GET',
            url: '/users',
            headers: { Authorization: `Bearer ${authToken}` }
          })
            .then(({ data }) => {
              setUsers(data.rows);
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
    setLoading(false);
  }, [authToken]);

  const handleSelect = (event) => {
    const user = users.find((item) => item.id === event.target.value);
    setSelectedUser(user);
  };

  return (
    <div className={'App'}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <img src={'logo.png'} style={{ marginTop: '10px' }} />

          {user.role === 'admin' ? (
            <>
              <DateWeekPicker
                startDateOffset={(day) => {
                  const startDate = getStartEndDate(day)[0];
                  setStartDate(startDate);
                  return startDate;
                }}
                endDateOffset={(day) => {
                  const endDate = getStartEndDate(day)[1];
                  setEndDate(endDate);
                  return endDate;
                }}
              />
              <InputLabel id="time">Select user</InputLabel>

              <Select
                id="timeGoogleCalendarId"
                labelId="time"
                style={{ width: 200 }}
                defaultValue={usedUser.id || user.id}
                onChange={handleSelect}
              >
                {users.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <button
                onClick={() => {
                  downloadHoursAsCSV(startDate, endDate, selectedUser);
                }}
                className="download-btn"
              >
                Download hours for week
              </button>
            </>
          ) : (
            <>
              <DateWeekPicker
                startDateOffset={(day) => {
                  const startDate = getStartEndDate(day)[0];
                  setStartDate(startDate);
                  return startDate;
                }}
                endDateOffset={(day) => {
                  const endDate = getStartEndDate(day)[1];
                  setEndDate(endDate);
                  return endDate;
                }}
              />
              <button
                onClick={() => {
                  downloadHoursAsCSV(startDate, endDate, user);
                }}
                className="download-btn"
              >
                Download hours for week
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Home;
