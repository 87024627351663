import React from 'react';
import '../../App.css';
import logoImg from './logo.png';
import { getCookieFromBrowser } from '../../utils/cookie';
import { GetUserByToken } from '../../helpers';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUsedFilter } from '../../redux/actions';

const styles = {
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 40,
    flexDirection: 'column'
  },
  logoText: {
    padding: 0,
    marginTop: 20,
    fontSize: 20,
    fontWeight: 'bold'
  },
  link: {
    padding: 20,
    textDecoration: 'none',
    borderBottom: '1px solid black',
    color: '#4eb5f1',
    margin: '4px 0',
    cursor: 'pointer'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
};

const { logo, logoText, link, item } = styles;

const token = getCookieFromBrowser('Authorization');

const sideBar = ({
  show,
  handleChangeTheme,
  theme,
  currentThmeIcon,
  usedUser,
  click,
  handleClearFilter
}) => {
  let drawerClasses = `side-bar ${theme}`;
  if (show) {
    drawerClasses = `side-bar open ${theme}`;
  }

  const { userLoading, user } = GetUserByToken(token);

  return (
    <nav className={drawerClasses}>
      <div>
        <div style={logo}>
          <img src={logoImg} />
          <p style={logoText}> Time Tracker</p>
        </div>
        <>
          {user.role === 'admin' ? (
            <div style={item}>
              {usedUser.name && (
                <Link to="#" style={link} onClick={() => handleClearFilter()}>
                  Cleat filter
                </Link>
              )}
              <Link to="/users" style={link} onClick={() => click()}>
                {usedUser.name || 'Users'}
              </Link>
              {usedUser.name === null &&
                <Link to="/google/calendar" style={link} onClick={() => click()}>
                  Google Calendar
                </Link>}
              <Link to="/time" style={link} onClick={() => click()}>
                Time Overview
              </Link>
              {usedUser.name === null && (
                <Link to="/" style={link} onClick={() => click()}>
                  Time Submit
                </Link>
              )}
              <Link to="/time/approve" style={link} onClick={() => click()}>
                Time Approve
              </Link>
              <Link to="/download" style={link} onClick={() => click()}>
                Download CSV
              </Link>
              <Link to="#" onClick={() => handleChangeTheme()} style={link}>
                {currentThmeIcon}
              </Link>
            </div>
          ) : (
            <div style={item}>
              <Link to="/time" style={link} onClick={() => click()}>
                Time Overview
              </Link>
              <Link to="/" style={link} onClick={() => click()}>
                Time Submit
              </Link>
              <Link to="/download" style={link} onClick={() => click()}>
                Download CSV
              </Link>
              <Link to="#" onClick={() => handleChangeTheme()} style={link}>
                {currentThmeIcon}
              </Link>
            </div>
          )}
        </>
      </div>
    </nav>
  );
};

export default sideBar;
