export function getGridData(data, type) {
  let rows = [];

  switch (type) {
    case 'overview':
      rows = data.reduce((rows, timeEntry) => {
        rows.push({
          ...timeEntry,
          userName: timeEntry.user?.name
        });

        return rows;
      }, rows);
      break;

    case 'invoice':
      rows = data.reduce((invoice, event) => {
        const isPresent = rows.filter(
          (rowEntry) =>
            rowEntry.user?.name === event.user?.name && rowEntry.project === event.project
        ).length;

        if (isPresent) {
          rows.map((rowEntry) => {
            if (rowEntry.user?.name === event.user?.name && rowEntry.project === event.project) {
              rowEntry.duration += event.duration;
              rowEntry.entries.push(event.id);
            }
          });
          return rows;
        }

        rows.push({
          ...event,
          userName: event.user?.name,
          entries: [event.id]
        });

        return rows;
      }, []);
      break;
    case 'approve':
      rows = data.reduce((rows, timeEntry) => {
        const isPresent = rows.filter(
          (rowEntry) =>
            rowEntry.user?.name === timeEntry.user?.name &&
            rowEntry.project === timeEntry.project &&
            rowEntry.type === timeEntry.type
        ).length;

        if (isPresent) {
          rows.map((rowEntry) => {
            if (
              rowEntry.user?.name === timeEntry.user?.name &&
              rowEntry.project === timeEntry.project &&
              rowEntry.type === timeEntry.type
            ) {
              rowEntry.duration += timeEntry.duration;
              rowEntry.entries.push(timeEntry.id);
            }
          });
          return rows;
        }

        rows.push({
          ...timeEntry,
          userName: timeEntry.user?.name,
          entries: [timeEntry.id]
        });

        return rows;
      }, []);
      break;
    default:
      break;
  }

  const projects = rows.reduce((projects, p) => {
    if (projects.filter((c) => c.id === p.project).length) {
      return projects;
    }
    p.project &&
      projects.push({
        id: p.project,
        label: p.project
      });

    return projects;
  }, []);

  const activities = rows.reduce((activities, p) => {
    if (activities.filter((c) => c.id === p.activity).length) {
      return activities;
    }
    p.activity &&
      activities.push({
        id: p.activity,
        label: p.activity
      });

    return activities;
  }, []);

  const users = rows.reduce((users, p) => {
    if (users.filter((c) => c.id === p.userName).length) {
      return users;
    }
    users.push({
      id: p.userName,
      label: p.userName
    });

    return users;
  }, []);

  const types = rows.reduce((types, p) => {
    if (types.filter((c) => c.id === p.type).length) {
      return types;
    }
    p.type &&
      types.push({
        id: p.type,
        label: p.type
      });

    return types;
  }, []);

  return { rows, projects, activities, users, types };
}

export function timeConvert(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + ' h ' : '';
  var mDisplay = m > 0 ? m + ' m ' : '';
  var sDisplay = s > 0 ? s + ' s' : '';
  return hDisplay + mDisplay + sDisplay;
}
