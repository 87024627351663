import { createTheme, ThemeProvider } from '@material-ui/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DARK } from '../constants/constants';

const darkThemeOptions = createTheme({
  palette: {
    type: DARK,
    primary: {
      main: '#3f51b5'
    },
    secondary: {
      main: '#f50057'
    }
  }
});

const defaultTheme = createTheme();

export const CustomThemeProvider = ({ children }) => {
  const currentTheme = useSelector((state) => state.theme);
  const theme = useMemo(() => {
    return currentTheme === DARK ? darkThemeOptions : defaultTheme;
  }, [currentTheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
