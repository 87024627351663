import React from 'react';
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { errorToast } from '../../utils/toast';
import { getCookieFromBrowser } from '../../utils/cookie';
import NoAvatar from '../../assets/img/no-avatar.jpeg';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    padding: '24px'
  },
  updateIcon: {
    marginTop: '8px',
    marginRight: '24px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    flexDirection: 'row'
  },
  h2Normal: {
    fontWeight: 'normal',
    marginLeft: '12px',
    marginTop: '6px',
    marginBottom: '6px'
  },
  h2Bold: {
    marginTop: '6px',
    marginBottom: '6px'
  }
};

const { wrapper, form, updateIcon, item, h2Normal, h2Bold } = styles;

const token = getCookieFromBrowser('Authorization');

const Show = ({ user, calendar }) => {
  const handleDelete = () => {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      },
      url: `/users/${user.id}`
    })
      .then((res) => window.location.replace('https://time.i2a.no/users'))
      .catch((e) => errorToast('Something went wrong.'));
  };

  return (
    <div style={wrapper}>
      <div style={updateIcon}>
        <Link
          key={user.id}
          to={{
            pathname: `/user/update/${user.id}`,
            state: user
          }}
          style={{ color: 'black' }}
        >
          <SettingsIcon />
        </Link>
        <div style={updateIcon} onClick={() => handleDelete()}>
          <DeleteIcon />
        </div>
      </div>
      <div style={form}>
        <div style={item}>
          <img style={{ width: 50, height: 50 }} src={user.picture ? user.picture : NoAvatar} />
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Name:</h2>
          <h2 style={h2Normal}>{user.name}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Email:</h2>
          <h2 style={h2Normal}>{user?.email}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Google Calendar</h2>
          <h2 style={h2Normal}>{calendar.summary ? calendar.summary : <CloseIcon />} </h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Google Refresh token(only for admin):</h2>
          <h2 style={h2Normal}>{user.refresh_token ? <CheckIcon /> : <CloseIcon />}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Monday user ID:</h2>
          <h2 style={h2Normal}>{user.mondayUserId}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Monday API Token:</h2>
          <h2 style={h2Normal}>{user.mondayApiToken ? <CheckIcon /> : <CloseIcon />}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Dag:</h2>
          <h2 style={h2Normal}>{user.dag}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Pause:</h2>
          <h2 style={h2Normal}>{user.pause}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Kost:</h2>
          <h2 style={h2Normal}>{user.kost}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Salgspris:</h2>
          <h2 style={h2Normal}>{user.salgspris}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Lonnsart:</h2>
          <h2 style={h2Normal}>{user.lonnsart}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Avdeling:</h2>
          <h2 style={h2Normal}>{user.avdeling}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Ansatt:</h2>
          <h2 style={h2Normal}>{user.ansatt}</h2>
        </div>
        <div style={item}>
          <h2 style={h2Bold}>Arbeidstype:</h2>
          <h2 style={h2Normal}>{user.arbeidstype}</h2>
        </div>
      </div>
    </div>
  );
};

export default Show;
