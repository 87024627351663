import React, { useMemo } from 'react';

import { styles, delCookie } from './HeaderMobile';

import './header.css';
import { NavLink } from 'react-router-dom';

import { ADMIN } from '../../constants/constants';

function HeaderDesctop({ authToken, role, handleChangeTheme, currentThmeIcon, usedUser }) {
  const { item, header } = styles;

  const isAdmin = role === ADMIN;

  return (
    <header>
      {authToken ? (
        <>
          <nav className="stroke">
            <ul>
              {isAdmin ? (
                <>
                  <li className={usedUser.name ? 'active-user' : ''}>
                    <NavLink
                      exact
                      activeClassName={usedUser.id ? '' : 'active'}
                      to={usedUser.name ? `/users/${usedUser.id}` : '/users'}
                    >
                      {usedUser.name || 'Users'}
                    </NavLink>
                  </li>
                  {usedUser.name === null &&
                    <li>
                      <NavLink exact activeClassName="active" to="/google/calendar">
                        Google Calendar
                      </NavLink>
                    </li>
                  }
                  <li>
                    <NavLink exact activeClassName="active" to="/time">
                      Time Overview
                    </NavLink>
                  </li>
                  {usedUser.name === null && (
                    <li>
                      <NavLink exact activeClassName="active" to="/">
                        Time Submit
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink exact activeClassName="active" to="/time/approve">
                      Time Approve
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact activeClassName="active" to="/download">
                      Download CSV
                    </NavLink>
                  </li>
                  <li onClick={() => handleChangeTheme()} style={{ color: '#4eb5f1' }}>
                    {currentThmeIcon}
                  </li>
                  <li>
                    <a href="/" onClick={delCookie}>
                      Logout
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink exact activeClassName="active" to="/time">
                      Time Overview
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact activeClassName="active" to="/">
                      Time Submit
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact activeClassName="active" to="/download">
                      Download CSV
                    </NavLink>
                  </li>
                  <li onClick={() => handleChangeTheme()} style={{ color: '#4eb5f1' }}>
                    {currentThmeIcon}
                  </li>
                  <li>
                    <a href="/" onClick={delCookie}>
                      Logout
                    </a>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </>
      ) : (
        <div style={header}>
          <div style={item}></div>
          <div className="auth-but-div">
            <div onClick={() => handleChangeTheme()} style={{ color: '#4eb5f1' }}>
              {currentThmeIcon}
            </div>
            <a className="auth-but" href="/auth">
              Login
            </a>
          </div>
        </div>
      )}
    </header>
  );
}

export default HeaderDesctop;
