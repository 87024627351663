import React, { useMemo } from 'react';
import { GetUsers } from '../../helpers';
import { getCookieFromBrowser } from '../../utils/cookie';
import Loader from '../../components/loader';
import { Link, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import NoAvatar from '../../assets/img/no-avatar.jpeg';
import User from '../users/show';
import useDeviceDetect from '../../hooks/useDeviceDetect';

import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { setUsedFilter, clearUsedFilter } from '../../redux/actions';

const Users = () => {
  const { users, loading } = GetUsers(getCookieFromBrowser('Authorization'));
  const { usedUser } = useSelector((state) => state);

  const { pathname } = useLocation();
  const pathId = pathname?.split('/')?.reverse()[0];

  const { isMobile } = useDeviceDetect();
  const dispatch = useDispatch();
  let history = useHistory();

  const handleSelectUser = (name, id) => {
    dispatch(setUsedFilter({ name, id }));
  };

  const handleClearFilter = () => {
    dispatch(clearUsedFilter());
    history.push('/users');
  };

  const isActive = (userId) => {
    return pathId && userId === pathId
      ? { background: '#4eb5f1', color: 'rgba(255, 255, 255, 1)' }
      : {};
  };

  return (
    <div className="main-container">
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <div className="users-container">
            {usedUser.id && (
              <div key="clear-button">
                <Link
                  className="users-item"
                  style={{ justifyContent: 'center' }}
                  to="#"
                  onClick={() => handleClearFilter()}
                >
                  Clear filter
                </Link>
              </div>
            )}
            {users.rows.map((user) => (
              <div key={user.id}>
                <Link
                  onClick={() => handleSelectUser(user.name, user.id)}
                  key={user.id}
                  to={{
                    pathname: isMobile ? `/user/${user.id}` : `/users/${user.id}`
                  }}
                  style={isActive(user.id)}
                  className="users-item"
                >
                  <img
                    src={user.picture ? user.picture : NoAvatar}
                    style={{ width: 50, height: 50, marginRight: 20, borderRadius: 25 }}
                  />
                  {user.name}
                </Link>
              </div>
            ))}
            <Link
              className="users-item"
              to={{
                pathname: `/users/create`
              }}
            >
              <ControlPointIcon style={{ width: 50, height: 50, marginRight: 20 }} /> Add new user
            </Link>
          </div>
        )}
      </div>
      <div>
        <Switch>
          <Route exact path="/users/:id" render={(props) => <User {...props} />} />
        </Switch>
      </div>
    </div>
  );
};

export default Users;
